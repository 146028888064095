@import "src/styles/colors";

.upButton {
  height: 48px;
  width: 48px;
  background-color: $primary-color;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.upButtonHidden {
  display: none;
}
