.am-picker {
    display: flex;
    flex-direction: row-reverse !important;
}
.am-picker-popup-header-right {
    justify-content: right;
    overflow: hidden;
    width: 100px;
}
.am-picker-popup-header-right:after {
    position: absolute;
    content: "Confirm";
    background: #FFFFFF;
    color: #680079;
}
