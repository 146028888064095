.blockWrap {
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  box-shadow: inset 0 1px 0 #F1F3F5;
  cursor: pointer;
}
.titleBlock {
  flex: 2;
  display: flex;
  flex-direction: column;
  margin: 15px;
  justify-content: space-between;
}
.imageBlock {
  flex: initial;
}
.image {
  height: 70px;
  width: 100px;
  margin: 15px;
  border-radius: 4px;
  object-fit: cover;
}
.title {
  font-size: 16px;
  font-weight: 500;
}
.categoryWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.category {
  color: #F78F49;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.indicator {
  height: 9px;
  width: 9px;
  background: #F78F49;
  border-radius: 9px;
  margin-right: 6px;
}
