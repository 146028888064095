html {
    margin: 0;
    height: 100%;
}
.wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #FFF3E8;
}
.logoWrapper {
    flex: 1;
}
.logo {
    width: fit-content;
    height: fit-content;
    margin: auto;
}
.logoText {
    text-align: center;
    font-size: 21px;
    font-weight: 700;
}
.empty1 {
    flex: 1;
}
.buttons {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
}
.button {
    border-radius: 24px;
    height: 47px;
    font-size: 18px;
    font-weight: 400;
    color: #FDE29C;
    width: 336px;
    cursor: pointer;
    margin: 0 auto 0 auto;
}
.buttonLogin {
    border: 1px solid #680079 !important;
    color: #680079;
}
.buttonSignup {
    border: 1px solid #680079 !important;
    background: #680079;
    color: #FDE29C;
}
