@import "src/styles/colors";

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  padding: 0 5% 0 5%;

  .moverContainer {
    width: 100%;
    height: 25px;
    min-height: 25px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .mover {
      height: 5px;
      width: 60px;
      background: #222222;
      opacity: 0.2;
      border-radius: 100px;
    }
  }
  .contentWrapper {
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      width: 100%;
      margin: 0 auto 0 auto;
      border-radius: 8px;
    }
    .imageBlocked {
      filter: grayscale(100%);
    }
    .pointsInfo {
      background-color: $beige_color;
      width: 100%;
      min-height: 60px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0 0;

      .pointsInfoTitle {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $charcoal_color;
        display: flex;
        flex-direction: row;
        align-items: center;

        .lockIcon {
          margin: 0 12px 0 0;
        }
        .successIcon {
          margin: 0 5px 0 0;
        }
      }
      .pointsInfoTitle p {
        margin: 0;
      }
      .pointsInfoPoints {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #EF7065;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-align: left;
        margin: 4px 0 0 0;

        .pointsInfoPointsIcon {
          margin-right: 8px;
        }
      }
      .pointsInfoPointsGreen {
        color: #69AA9E;
      }
    }
    .pointsInfoBlocked {
      background-color: #DADADA;
    }
    .pointsInfoClaimed {
      background-color: rgba(105, 170, 158, 0.1);
    }
    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      margin: 20px auto 0 auto;
    }
    .text {
      margin: 20px auto 20px auto;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.0062em;
    }
  }

  .line {
    width: 100%;
    height: 0;
    border: 1px solid #DADADA;
    opacity: 0.5;
  }
  .issueText {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 40px 10% 20px 10%;
    color: $charcoal_color;
  }
}
