@import "src/styles/colors";

.backButton {
  font-size: 16px;
  width: 90%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin: 30px auto 20px auto;
}
.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin: 22px 15px 15px 15px;
  color: $primary_color;
}
.articleInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 20px 0;

  .category {
    color: #F78F49;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-align: left;

  }
}
.articleWrapper {
  width: 90%;
  margin: 0 auto 0 auto;
  padding: 0 0 30px 0;
  height: calc(100% - 190px);

  .bottomEmpty {
    height: 70px;
  }
}
.articleWrapper img {
  width: 100%;
}
