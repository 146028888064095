.navBar {
    background: none;
    margin-top: 40px;
}
.inputWrapper {
    flex: 4;
}
.input {
    margin: 20px auto 20px auto;
}
.status {
    width: 90%;
    margin: 0 auto 0 auto;
    text-align: center;
}
.buttonWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
}
.forgotPassword {
    color: #680079;
    border: none;
    background: none;
    font-weight: 700;
    font-size: 12px;
}
.caption {
    max-width: 70%;
    text-align: center;
    margin: 30px auto 0 auto;
    font-size: 14px;
}
