@import "src/styles/colors";

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;

  .mainBlock {
    .skipContainer {
      height: 45px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 24px 0 0 0;

      .skipButton {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
        color: $primary_color;
        margin: 0 24px 0 0;
        cursor: pointer;
      }
    }
    .contentContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .image {
        width: 100%;
        margin: 0 18px 0 18px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .svgImg {
          height: 100%;
          object-fit: scale-down;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        .svgImg > div {
          height: 100%;
        }
        .svgImg > div > svg {
          height: 100%;
        }
      }
      .content {
        margin: 5% 32px 0 32px;
        font-size: 3vh;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        text-align: center;
        width: calc(100% - 63px);
      }
    }
  }
  .progress {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
    margin: 6% 0 4% 0;
  }
}
