@import "src/styles/colors";
@import "src/styles/sizes";

@mixin menuItem {
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 2px 0 2px 0;

  .icon {
    height: 24px;
    width: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 17px 0 15px;
  }
  .title {
    width: 100%;
  }
  .arrow {
    width: 24px;
    height: 24px;
    margin: 0 10px 0 0;
  }
}

.backHeader {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, $primary_color 25%, transparent 0);
  overflow: visible;

  .headerTitle {
    margin: 20px 0 0 20px;
    color: #FFFFFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0.02em;
  }

  .cardWrap {
    width: 100%;
    max-width: $defaultWidth;
    @media (max-width: $maxWidth) {
      max-width: 100%;
    }
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
      width: 90%;
      margin: 0 auto 0 auto;
      box-shadow: 0 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
      background: #FFFFFF;
      border-radius: 8px;

      .topBlock {
        height: 160px;
        border-bottom: 1px solid #DADADA;

        .userInfo {
          display: flex;
          flex-direction: column;
          align-items: center;

          .photo {
            height: 100px;
            width: 100px;
            position: absolute;
            border: 4px solid #FFFFFF;
            margin: -24px auto 0 auto;
            border-radius: 60px;
            background: #FFFFFF;
            overflow: hidden;
          }
          .photo > img {
            height: 100px;
            width: 100px;
          }
          .name {
            margin: 96px auto 0 auto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0;
            text-align: center;
            color: $charcoal_color;
          }
          .email {
            margin: 8px auto 0 auto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0;
            text-align: center;
            color: $medium_color;
          }
        }
      }

      .buttonList {
        margin: 10px 0 0 0;

        .menuItem {
          @include menuItem;
        }
        .logout {
          @include menuItem;
          margin: 10px 0 10px 0;
          color: $error_color;
        }
      }
    }
  }
}
