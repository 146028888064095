.wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin: 30px 0 0 0;

  .leftButton {
    flex: 1;
    margin: 0 0 0 15px;
  }
  .title {
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;

  }
  .rightButton {
    flex: 1;
  }
}
