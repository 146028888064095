@import "src/styles/sizes";

.react-datepicker {
    border: 1px solid #9500AD !important;
    border-radius: 20px;
    font-family: Poppins;
    width: 100%;
    margin: 0 auto 0 auto;
}
.react-datepicker__navigation {
    color: #9500AD;
}
.react-datepicker__navigation-icon::before {
    border-color: #9500AD;
}
.react-datepicker__month-container {
    width: 100%;
}
.react-datepicker__header {
    border-radius: 20px;
    background: none;
    border: none;
    width: 100%;
}
.react-datepicker__month-container {
    border-radius: 20px;
}
.react-datepicker__day-names {
    border-bottom: 1px solid #9500AD;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.react-datepicker__current-month {
    margin: 0 auto 14px auto;
    color: #9500AD;
}
.react-datepicker__week {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.react-datepicker__header__dropdown {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.react-datepicker__month-dropdown-container {
    flex: 1;
}
.react-datepicker__year-dropdown-container {
    flex: 1;
}
.react-datepicker__month-select {
    height: 30px;
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    padding-left: 10px;
    font-size: 14px;
}
.react-datepicker__year-select {
    height: 30px;
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    padding-left: 10px;
    font-size: 14px;
}
.react-datepicker__day--selected {
    background: #680079;
}

.am-picker-popup-wrap {
    max-width: $defaultWidth;
    @media (max-width: $maxWidth) {
        max-width: 100%;
    }
    width: 100%;
    margin: 0 auto 0 auto;
}
