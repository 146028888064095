.wrap {
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;

  .question {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin: 29px 45px 0 45px;
    color: #30353F;
  }

  .description {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    margin: 15px 30px 15px 30px;
    color: #5A6265;
  }

  .buttons {
    height: 64px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #DADADA;;

    .cancelButton {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #5A6265;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
      text-align: center;
      padding: 0 10px 0 10px;
    }
    .divider {
      height: 40px;
      width: 0;
      border: 1px solid #DADADA;
      margin: 7px 0 7px 0;
    }
    .confirmButton {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #680079;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      padding: 0 10px 0 10px;
      cursor: pointer;
    }
  }
}
