@import "src/styles/sizes";

.dialogWrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;

  .dialog {
    width: 100%;
    max-width: $defaultWidth;

    @media (max-width: $maxWidth) {
      max-width: 100%;
    }
  }
}
