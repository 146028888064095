html {
    margin: 0;
    height: 100%;
}
.navBar {
    background: none;
    margin-top: 40px;
    font-weight: 700;
}
.navBar svg {
    color: black;
}
.agreeText {
    font-size: 14px;
    padding-top: 1px;
}
.agreeCheckBox {
    margin: 4px 4px 0 10px;
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.agreeLabel::before {
    content: '';
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 2px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    display: flex;
    align-items: center;
}
.agreeLabelChecked:before {
    border-color: #69AA9E;
    background-color: #69AA9E;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.agreeWrapper {
    display: flex;
    margin: 0 auto 0 auto;
    width: 90%;
}
.list:after {
    background: none;
    border-bottom: none !important;
}
.inputWrapper {
    flex: 3;
}
.input {
    margin: 20px auto 20px auto;
}
.buttonWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 20px auto 30px auto;
}
@mixin inlineButtonDef {
    color: #680079;
    border: none;
    background: none;
    font-weight: 700;
    padding: 0;
    cursor: pointer;
}
@mixin inlineButtonRegularDef {
    @include inlineButtonDef;
    font-weight: 400;
}
.inlineButton {
    @include inlineButtonDef;
}
.inlineButtonPadding {
    @include inlineButtonDef;
    padding: 0 6px 0 6px;
}
.inlineButtonRegular {
    @include inlineButtonRegularDef;
}
.inlineButtonPaddingRegular {
    @include inlineButtonRegularDef;
    padding: 0 6px 0 6px;
}
.haveAccountWrapper {
    text-align: center;
    margin: 30px auto 0 auto;
}
.scrollableWrap {
    overflow: hidden;
    margin-bottom: 80px;
}
.scrollable {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px;
    box-sizing: content-box;
}
