.tabs {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  cursor: pointer;
  width: calc(100% + 17px);
}
@mixin defaultTab {
  flex: 1;
  text-align: center;
  height: 44px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  line-height: 28px;
}
.tab {
  @include defaultTab;
}
.tabActive {
  @include defaultTab;
  color: #EF7065;
  border-bottom: 2px solid #EF7065;
  font-weight: 700;
}
