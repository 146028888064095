html {
    margin: 0;
    height: 100%;
}
.scrollableWrap {
    overflow: hidden;
    margin-bottom: 60px;
}
.scrollable {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px;
    box-sizing: content-box;
}
.hello {
    width: 90%;
    margin: 30px auto 0 auto;
    font-size: 18px;
    color: #680079;
}
.ageRound {
    margin: 30px auto 20px auto;
    height: 190px;
    width: 190px;
    border: 0 solid rgba(141, 49, 144, 1);
    border-radius: 100px;
    background: linear-gradient(150.92deg, #EF7065 30.26%, #9500AD 67.78%);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ageContainer {
    height: 152px;
    width: 152px;
    text-align: center;
    background-color: #FFFFFF;
    border: 8px solid rgba(141, 49, 144, 1);
    border-radius: 90px;
    margin: 11px;
}
.realAge {
    position: relative;
    left: 120px;
    background: #F07166;
    height: 32px;
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    border: 2px solid #F2F2F3;
}
.ageText {
    margin: 0 auto 0 auto;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    width: 80px;
    line-height: 13px;
}
.ageValue {
    font-size: 28px;
    line-height: 36px;
    color: #9500AD;
    margin-top: 10px;
    font-weight: bold;
}
.bioAgeText {
    font-size: 12px;
    width: 90%;
    margin: 0 auto 0 auto;
    text-align: center;
}
.statisticsBlock {
    height: 72px;
    display: flex;
    flex-direction: row;
    box-shadow: 2px 6px 25px rgba(32, 31, 32, 0.1);
    border-radius: 8px;
    margin: 20px auto 0 auto;
    width: 90%;
}
.divider {
    width: 0;
    border: 1px solid #30353F;
    height: 30px;
    opacity: 0.1;
    margin-top: 20px;
}
.statistics {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
}
.statCaption {
    text-transform: uppercase;
    font-size: 12px;
    color: #30353F;
}
.categoryTitle {
    width: 90%;
    height: 28px;
    margin: 20px auto 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.categoryName {
    font-size: 18px;
    font-weight: 500;
}
.categoryButton {
    border: 0;
    cursor: pointer;
    color: #9500AD;
    text-transform: uppercase;
    background-color: transparent;
    font-weight: 500;
    font-family: Poppins;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.successChallengeInfo {
    background-color: #FFFFFF;
    width: 90%;
    margin: 18px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;

    .successChallengeInfoIcon {
        margin: 25px 0 0 0;
        max-width: 50px;
        max-height: 50px;
    }
    .successChallengeInfoIcon > div > div > svg {
        max-width: 50px;
        max-height: 50px;
    }
    .successChallengeInfoTitle {
        margin: 5px 0 0 0;
    }
    .successChallengeInfoPoints {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        color: #69AA9E;
        margin: 19px 0 22px 0;

        .successChallengeInfoPointsDivider {
            width: 12px;
        }
    }
}
