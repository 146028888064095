@import "src/styles/colors";

@mixin menuItem {
  cursor: pointer;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  font-style: normal;
  line-height: 50px;
  letter-spacing: 0;
  text-align: center;
}

.dialogWrap {
  background: #FFFFFF;

  .title {
    height: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    color: #BDC0C1;
  }
  .options {
    cursor: pointer;

    .option {
      @include menuItem;
    }
    .activeOption {
      @include menuItem;
      font-weight: 700;
      letter-spacing: 0;
    }
  }
  .cancel {
    @include menuItem;
    color: $error_color;
  }
}
