.wrapNew {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    margin: 10px auto 10px auto;
    cursor: text;

    .placeholder {
        width: 90%;
        margin: auto;
        font-size: 15px;
        font-weight: 400;
        color: #680079;
        height: 17px;
        line-height: 20px;
        font-style: normal;
        text-align: left;
    }
    .inputContainer {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 90%;
        margin: 0 auto 0 auto;
        border-bottom: 1px solid rgba(189, 192, 193, 0.3);
        padding-bottom: 4px;

        .inputNew {
            flex: 1;
            border: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            padding-left: 0;
            letter-spacing: 0.1em;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type=number] {
            -moz-appearance: textfield;
        }
        .inputNewNoFlex {
            flex: none;
        }
        .extra > div {
            position: initial;
            padding: 0;
            height: fit-content;
            color: #5A6265;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
        }
    }
    .errorMessage {
        margin: 6px auto 0 auto;
        font-size: 12px;
        color: #F13642;
        height: 14px;
        width: 90%;
    }
}

