@import "src/styles/colors";

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px auto 15px auto;

  .titleContainerTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: $charcoal_color;
  }
  .titleContainerButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 1px;
    text-align: right;
    color: #9500AD;
    text-transform: uppercase;
    cursor: pointer;

    .titleContainerButtonArrow > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
