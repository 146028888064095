.wrap {
    text-align: center;
}
.button {
    font-family: Poppins;
    min-height: 38px;
    width: 90%;
    margin: 10px auto 0 auto;
    border-radius: 8px;
    background-color: #F2F2F3;
    color: #30353F;
    border: 1px solid #BDC0C1;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
}
.buttonActive {
    font-family: Poppins;
    min-height: 38px;
    width: 90%;
    margin: 10px auto 0 auto;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    background-color: #F7F2F8;
    border: 1px solid #9500AD;
    color: #9500AD;
    padding: 10px;
}
