html {
    margin: 0;
    height: 100%;
}
.navBar {
    background: none;
    margin-top: 40px;
}
.navBar svg {
    color: black;
}
.inputWrapper {
    flex: 4;
}
.input {
    margin: 20px auto 20px auto;
}
.agreeText {
    margin-left: 10px;
    font-size: 14px;
}
.agreeCheckBox {
    margin: 4px 4px 0 10px;
}
.agreeWrapper {
    display: flex;
}
.list:after {
    background: none;
    border-bottom: none !important;
}
.button {
    border-radius: 24px;
    height: 47px;
    font-size: 18px;
    font-weight: 400;
    color: #FDE29C;
    width: 336px;
    cursor: pointer;
    margin: 0 auto 0 auto;
}
.buttonSignup {
    border: 1px solid #680079 !important;
    background: #680079;
    color: #FDE29C;
}
.buttonWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
}
.inlineButton {
    color: #680079;
    border: none;
    background: none;
    font-weight: 700;
}
.haveAccountWrapper {
    text-align: center;
    margin: 30px auto 0 auto;
    width: fit-content;
}
.list > div.am-list-body::after {
    background-color: white;
}
.flexItem > div {

}
.forgotPassword {
    color: #680079;
    border: none;
    background: none;
    font-weight: 700;
    font-size: 12px;
    height: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: -20px 0 0 0;
    padding-right: 5%;
}
.errorMessage {
    width: 90%;
    margin: 4px auto 0 auto;
    padding: 0 0 0 14px;
    font-size: 14px;
    color: #F13642;
}
.errorMessageHidden {
    display: none;
}
