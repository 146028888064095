@import "src/styles/sizes";

.main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.modalBack {
    height: 100%;
    width: $defaultWidth;
    max-width: $defaultWidth;
    @media (max-width: $maxWidth) {
        width: 100%;
        max-width: 100%;
    }
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 auto 0 auto;
}
.modalWrap {
    background-color: #FFFFFF;
    max-width: $defaultWidth;

    @media (max-width: $maxWidth) {
        max-width: 100%;
    }
    width: 100%;
    margin: 0 auto 0 auto;
    height: 80vh;
    bottom: 0;
    z-index: 1;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
}
.stick {
    width: 50px;
    height: 5px;
    background: #222222;
    opacity: 0.2;
    border-radius: 5px;
    margin: 20px auto 0 auto;
}
.icon {
    height: 54px;
    width: 54px;
    margin: 30px auto 0 auto;
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #F7F2F8;
    border-radius: 30px;
}
.icon > div > div > svg {
    height: 30px;
    width: 30px;
}
.title {
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin: 20px auto 0 auto;
}
.challengeInfo {
    display: flex;
    flex-direction: row;
    margin: 20px auto 0 auto;
    text-align: center;
}
.challengeInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5A6265;
    font-size: 12px;
    font-weight: 400;
    margin: 0 5px 0 5px;
}
.challengeInfoItemIcon {
    margin: 0 4px 0 0;
}
@mixin pointInfoBase {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 60px;
    width: 90%;
    margin: 20px auto 0 auto;
    justify-content: center;
    border-radius: 4px;
}
.pointsInfo {
    @include pointInfoBase;
    background: #FCEDE3;
}
.pointsInfoFinished {
    @include pointInfoBase;
    background: rgba(105, 170, 158, 0.1);
}
.pointsInfoTitle {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
}
@mixin pointsInfoPointsBase {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 8px 0 0 0;
}
.pointsInfoPoints {
    @include pointsInfoPointsBase;
    color: #EF7065;
}
.pointsInfoPointsFinished{
    @include pointsInfoPointsBase;
    color: #69AA9E;
}
.challengeText {
    font-size: 14px;
    line-height: 24px;
    width: 90%;
    margin: 20px auto 0 auto;
}
.buttonWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 10px auto 0 auto;
    padding-bottom: 30px;
}
.typeformButton {
    background: none;
    border: none;
    padding: 0;
    text-align: left;
}
.pointsIcon {
    margin: 0 6px 0 0;
    height: 100%;
}
.pointsIcon > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.divider {
    height: 3px;
    width: 3px;
    border-radius: 5px;
    background-color: #C4C4C4;
    margin: 0 5px 0 5px;
}
.challengeCategory {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 23px 0 0 0;

    .categoryIcon {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 6px 0 0;
    }
    .categoryIcon > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
