html {
    margin: 0;
    height: 100%;
}
.navBar {
    background: none;
    margin-top: 40px;
}
.agreeText {
    margin-left: 10px;
    font-size: 14px;
}
.agreeCheckBox {
    margin: 4px 4px 0 10px;
}
.agreeWrapper {
    display: flex;
    margin: 0 20px 0 10px;
}
.list:after {
    background: none;
    border-bottom: none !important;
}
.inputWrapper {
    flex: 3;
}
.input {
    margin: 20px auto 20px auto;
}
.buttonWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 20px auto 30px auto;
}
.inlineButton {
    color: #680079;
    border: none;
    background: none;
    font-weight: 700;
    padding: 0;
    cursor: pointer;
}
.inlineButtonPadding {
    color: #680079;
    border: none;
    background: none;
    font-weight: 700;
    padding: 0 6px 0 6px;
    cursor: pointer;
}
.haveAccountWrapper {
    text-align: center;
    margin: 30px auto 0 auto;
}
.scrollableWrap {
    overflow: hidden;
    margin-bottom: 80px;
}
.scrollable {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px;
    box-sizing: content-box;
}
