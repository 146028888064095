.challengeBlock {
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    width: 90%;
    margin: 10px auto 10px auto;
    cursor: pointer;
    border: 1px solid #DADADA;
    box-sizing: border-box;
}
.challengeBlockIcon {
    width: 50px;
    min-width: 50px;
    height: 40px;
    font-size: 30px;
    text-align: center;
    border-right: 1px solid #E7E8E9;
    margin: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.challengeBlockIcon > div > div > svg {
    height: 30px;
    width: 30px;
}
.challengeBlockData {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.challengeBlockDataProgress {
    width: 50px;
    height: 4px;
    background: #F8E2DA;
    border-radius: 8px;
    margin-top: 4px;
}
.challengeBlockDataProgressIndicator {
    height: 4px;
    border-radius: 8px;
    background: linear-gradient(112.95deg, #EF7065 16%, #9500AD 85.12%);
}
.challengeBlockDataName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}
.challengeBlockInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.challengeBlockInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5A6265;
    font-size: 12px;
    font-weight: 400;
}
.challengeBlockInfoItemIcon {
    margin: 0 6px 0 0;
    height: 100%;
}
.challengeBlockInfoItemIcon > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.arrow {
    margin: 0 7px 0 0;
}
.divider {
    height: 3px;
    width: 3px;
    border-radius: 5px;
    background-color: #C4C4C4;
    margin: 0 5px 0 5px;
}
