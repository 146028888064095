@import "src/styles/sizes";

.main {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}
.modalBack {
  height: 100%;
  width: 100%;
  max-width: $defaultWidth;
  @media (max-width: $maxWidth) {
    max-width: 100%;
  }
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .modalWrap {
    width: 100%;
    max-height: 100vh;
    max-width: $defaultWidth;
    @media (max-width: $maxWidth) {
      max-width: 100%;
    }
    background-color: #FFFFFF;
    margin: 0 auto 0 auto;
    bottom: 0;
    z-index: 1;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
  }
}
