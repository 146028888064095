html {
    margin: 0;
    height: 100%;
}
.navBar {
    background: none;
    margin-top: 40px;
}
.inputWrapper {
    flex: 3;
}
.input {
    margin: 20px auto 20px auto;
}
.buttonWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 20px auto 30px auto;
}
.errorMessage {
    width: 90%;
    margin: 4px auto 0 auto;
    padding: 0 0 0 14px;
    font-size: 14px;
    color: #F13642;
}
.errorMessageHidden {
    display: none;
}
