@import 'src/styles/sizes';

$activeColor: #680079;
$inactiveColor: #BDC0C1;

.bottomMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05);
  height: 60px;
  position: absolute;
  bottom: 0;
  max-width: $defaultWidth;
  @media (max-width: $maxWidth) {
    max-width: 100%;
  }
  width: 100%;
  background: #FFFFFF;

  .bottomMenuItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
    cursor: pointer;

    .bottomMenuItemIconInactive {
      filter: invert(82%) sepia(5%) saturate(126%) hue-rotate(145deg) brightness(89%) contrast(94%);
    }

    .bottomMenuItemIconActive {
      filter: invert(11%) sepia(51%) saturate(5078%) hue-rotate(283deg) brightness(103%) contrast(119%);
    }

    .bottomMenuItemText {
      margin-top: 4px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      color: $inactiveColor;
    }

    .bottomMenuItemTextActive {
      margin-top: 4px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      color: $activeColor;
    }
  }
}
