@import "src/features/bamQuestionnaire/styles/shared";

.wrap {
    @include screenWrapWhite;
}
.navBarCustom {
    @include navBar;
}
.navBarCustomLeft {
    display: flex;
    align-items: center;
    flex: 1;
}
.navBarCustomCenter {
    flex: 2;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}
.navBarCustomRight {
    flex: 1;
}
.navBarCustomText {
    font-size: 16px;
    color: #30353F;
    margin-left: 10px;
}
.logoWrapper {
    margin-top: 10%;
    margin-bottom: 0;
}
.logo {
    width: fit-content;
    height: fit-content;
    margin: auto;
}
.question {
    width: 90%;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    margin: 10px auto 0 auto;
    color: #9500AD;
}
.buttonWrap {
    @include buttonWrap;
}
.progress {
    height: 8px;
    width: 90%;
    margin: auto;
    border-radius: 4px;
}
.progress > .am-progress-bar {
    height: 8px;
}
.inputWrapper {
    margin-top: 30px;
}
.description {
    @include description;
}
