.wrap {
    background-color: #680079;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.navBarWrapper{
    flex: 1;

    .navBarCustom {
        margin: 30px 0 0 0;
        display: flex;
        align-items: center;
        height: 45px;
        width: 100%;
        padding-left: 10px;
        cursor: pointer;

        .navBarCustomText {
            font-size: 16px;
            color: #FFFFFF;
            margin-left: 10px;
        }
    }
}
.contentWrapper {
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logoWrapper {
        margin-bottom: 0;
    }
    .logo {
        width: fit-content;
        height: fit-content;
        margin: 0 auto 24px auto;
    }
    .title {
        height: 32px;
        width: 100%;
        text-align: center;
        font-size: 21px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .content {
        font-size: 16px;
        width: 90%;
        color: #FFFFFF;
        text-align: center;
        margin: 16px auto 0 auto;
        line-height: 24px;
    }
}
.buttonWrapper {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
}
.button {
    border-radius: 24px;
    height: 47px;
    font-size: 18px;
    font-weight: 400;
    border: 1px solid #680079 !important;
    color: #680079;
    width: 336px;
    cursor: pointer;
    margin: 0 auto 0 auto;
}
.am-navbar-title {
    color: #FFFFFF;
}
