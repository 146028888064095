@import "src/styles/colors";

.blockWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;
    cursor: pointer;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 0 15px 0;

    .imagePointContainer {
        position: relative;

        .imageContainer {
            width: 100%;
            height: 122px;
            overflow: hidden;

            .image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .imageBlocked {
                filter: grayscale(100%);
            }
        }
        .points {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: #FFFFFF;
            position: absolute;
            top: 15px;
            left: 15px;
            height: 24px;
            min-width: 43px;
            padding: 0 5px 0 5px;
            border-radius: 12px;

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            letter-spacing: 0.1em;
        }
    }
    .bottomSection {
        display: flex;
        flex-direction: column;

        .needMoreMessage {
            margin: 15px 15px 0 15px;
            text-transform: uppercase;

            color: $medium_color;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-align: left;
        }
        .blockedMessage {
            margin: 15px 15px 0 15px;
            display: flex;
            flex-direction: row;

            text-transform: uppercase;
            color: $charcoal_color;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-align: left;

            .blockedMessageIcon {
                margin-right: 5px;
            }
        }
        .title {
            margin: 15px 10px 15px 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: calc(100% - 25px);

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-align: left;

            .arrowRight {
                margin: 0 0 0 auto;
            }
            .arrowRight > div > div {
                display: flex;
            }
        }
        .titleBlocked {
            color: $charcoal_color;
            opacity: 0.5;
        }
    }
}
