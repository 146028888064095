@import "src/styles/colors";

.pageBack {
  z-index: 3;
}
.pageBackHidden {
  display: none;
}

.wrap {
  height: 100%;
  width: 100%;
  background: #F0F7F5;
  display: flex;
  flex-direction: column;

  .icon {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0 0 0;
  }
  .content {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 49px 0 0 0;

    .title {
      width: 90%;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      color: #30353F;
      text-align: center;
    }
    .description {
      width: 90%;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #30353F;
      opacity: 0.6;
      text-align: center;
      margin: 9px 0 0 0;
    }
  }
}
