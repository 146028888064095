@import "src/styles/sizes";

.wrap {
  width: 100%;
  background: #680079;
  display: flex;
  flex-direction: column;
  height: 100%;

  .contentWrapper{
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;

    .logoWrapper {

      .logo {

      }
    }
    .title {
      color: #FFFFFF;
      font-size: 21px;
      margin: 20px auto 0 auto;
    }
    .content {
      color: #FFFFFF;
      font-size: 16px;
      margin: 20px auto 0 auto;
      text-align: center;
      width: 90%;
    }
  }
  .buttonWrapper {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
    margin: 20px auto 0 auto;
  }
}
