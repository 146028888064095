.button {
    border-radius: 24px;
    height: 47px;
    font-size: 18px;
    font-weight: 700;
    color: #FDE29C !important;
    width: 336px;
    cursor: pointer;
    margin: 0 auto 0 auto;
}
.button::before {
    border: none !important;
    border-radius: 24px !important;
}
.buttonEmpty {
    border: 1px solid #680079 !important;
    color: #680079 !important;
}
.buttonFilled {
    border: 0 solid #680079 !important;
    background: #680079;
    color: #FDE29C;
}
.buttonTransparent {
    background: none;
}
