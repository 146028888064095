@import "src/styles/colors";

.date {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  color: $primary_color;
  margin: 24px 0 10px 15px;
}
.rewardsContainer {
  width: 90%;
  margin: 0 auto 0 auto;
}
