@import "src/styles/sizes";

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: $defaultWidth;
  position: relative;

  @media (max-width: $maxWidth) {
    max-width: 100%;
  }
  margin: 0 auto 40px auto;
  font-family: Poppins;
  background: #FFFFFF;
}
