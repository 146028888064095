@import "src/features/bamQuestionnaire/styles/shared";

.wrap {
    @include screenWrapWhite;
    background-color: #FFF5EA;
    background-image: url("./result-back.png");
    background-repeat: no-repeat;
    background-position: bottom;
}
.contentWrapper {
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.navBarCustom {
    @include navBar;
}
.navBarCustomText {
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 10px;
}
.logoWrapper {
    margin-top: 30%;
    margin-bottom: 0;
}
.logo {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-bottom: -40px;
}
.title {
    height: 32px;
    width: 100%;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    color: #30353F;
    margin: 30px auto 0 auto;
}
.content {
    font-size: 16px;
    width: 90%;
    color: #30353F;
    text-align: center;
    margin: 16px auto 0 auto;
    line-height: 24px;
}
.buttonWrap {
    @include buttonWrap;
}
.button {
    border-radius: 24px;
    height: 47px;
    font-size: 18px;
    font-weight: 400;
    border: 1px solid #680079 !important;
    color: #680079;
    width: 336px;
    cursor: pointer;
    margin: 0 auto 0 auto;
}
.am-navbar-title {
    color: #FFFFFF;
}
.ageBack {
    margin: 30px auto 0 auto;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;

    .ageContainer {
        margin: 110px auto 40px auto;
        text-align: center;

        .ageText {
            margin: 0 auto 0 auto;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            line-height: 13px;
        }
        .ageValue {
            font-size: 48px;
            line-height: 36px;
            color: #9500AD;
            margin-top: 10px;
            font-weight: bold;
        }
    }
}
