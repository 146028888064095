@mixin navBar {
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  padding-left: 10px;
  cursor: pointer;
  margin: 30px 0 0 0;
}
@mixin screenWrapWhite {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
@mixin buttonWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;
  margin: 30px auto 0 auto;
}
@mixin description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  width: 70%;
  margin: 12px auto 0 auto;
  color: #5A6265;
}
