@import "src/styles/colors";

.wrap {
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: #FEF6E1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.navBarCustom {
    display: flex;
    align-items: center;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    cursor: pointer;
}
.navBarCustomText {
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 10px;
}
.logoWrapper {
    margin-bottom: 0;
}
.logo {
    width: fit-content;
    height: fit-content;
    margin: 0 auto 49px auto;
}
.title {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    color: $charcoal_color;
    margin: 0 0 9px 0;
}
.content {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    width: 90%;
    margin: 0 auto 0 auto;
    color: #5A6265;
}
.haventText {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $charcoal_color;
    margin: 51px 0 0 0;
}
.resendButton {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    color: #9500AD;
    margin: 20px 0 30% 0;
    text-decoration-line: underline;
    cursor: pointer;
}
