@import "src/styles/colors";

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .dataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .moverContainer {
      width: 100%;
      height: 25px;
      min-height: 25px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .mover {
        height: 5px;
        width: 60px;
        background: #222222;
        opacity: 0.2;
        border-radius: 100px;
      }
    }
    .pointsSystemIcon {
      margin: 0 auto 0 auto;
    }
    .pointsSystemText {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: $charcoal_color;
      margin-top: 20px;
    }
    .totalPointsWonText {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: $charcoal_color;
      margin-top: 46px;
    }
    .sumOfPoints {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $charcoal_color;
      margin-top: 11px;
    }
    .totalPointsText {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: $charcoal_color;
      margin: 36px 0 0 0;
    }
  }
}

.successClaimText {
  width: 90%;
  color: $medium_color;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.successClaimInfoContainer {
  background-color: #FFFFFF;
  border-radius: 8px;
  margin: 27px 30px 0 30px;
  width: 80%;

  .successClaimInfoContainerTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin: 24px 5% 0 5%;
  }
  .successClaimInfoContainerPoints {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: #69AA9E;

    margin: 7px 0 30px 0;

    .successClaimInfoContainerPointsIcon {
      margin-right: 8px;
    }
  }
}

.rewardsWrap {
  background: #FFFFFF;
  padding: 0 5% 0 5%;
  margin-bottom: 75px;

  .title {
    font-weight: 500;
    font-size: 22px;
    margin: 30px auto 0 auto;
    color: #680079;
  }

  .pointsInfoBlock {
    background-color: $beige_color;
    margin: 20px auto 20px auto;
    padding: 10px 10px 15px 15px;
    border-radius: 8px;

    .pointsInfoBlockTitle {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin: 3px 0 0 3px;

      text-transform: uppercase;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0.05em;

      .pointsInfoBlockTitleIcon {
        margin: 0 13px 0 0;
      }
      .pointsInfoBlockTitleInfoButton {
        margin: 0 0 0 auto;
        cursor: pointer;
      }
    }
    .pointsSection {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 16px 0 0 0;

      .pointsSectionPartition {
        width: 50%;
        margin: 0 0 0 3px;

        .pointsSectionPartitionTitle {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0.05em;
          color: $medium_color;
        }
        .pointsSectionPartitionPoints {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: $charcoal_color;
          margin: 3px 0 0 0;
        }
      }
    }
  }

  .needMoreMessage {

  }
  .bockedMessage {

  }
}
