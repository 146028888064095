@import "src/styles/colors";

.title {
  width: 100%;
  margin: 23px 0 9px 15px;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0.02em;
  text-align: left;
  color: $primary_color;
}
.scrollableWrap {
  overflow: hidden;
  margin-bottom: 60px;
}
.scrollable {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
}
.successChallengeInfo {
  background-color: #FFFFFF;
  width: 90%;
  margin: 18px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;

  .successChallengeInfoIcon {
    margin: 25px 0 0 0;
    max-width: 50px;
    max-height: 50px;
  }
  .successChallengeInfoIcon > div > div > svg {
    max-width: 50px;
    max-height: 50px;
  }
  .successChallengeInfoTitle {
    margin: 5px 0 0 0;
  }
  .successChallengeInfoPoints {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: #69AA9E;
    margin: 19px 0 22px 0;

    .divider {
      width: 12px;
    }
  }
}
.join {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 20px auto 15px auto;

  .joinTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: $charcoal_color;
  }
  .completedButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 1px;
    text-align: right;
    color: #9500AD;
    text-transform: uppercase;
    cursor: pointer;

    .completedArrow > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
