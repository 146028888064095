.scrollableWrap {
  overflow: hidden;
  height: 100%;
}
.scrollableWrapAdaptive {
  overflow: hidden;
}
.scrollable {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 17px;
  box-sizing: content-box;
}
.upButtonContainer {
  position: absolute;
  top: calc(100% - 50px);
  left: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;

  .upButton {
    position: relative;
    right: 20px;
    bottom: 20px;
  }
}
