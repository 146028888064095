@import "src/styles/sizes";

.dialogWrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .dialogContainer {
    width: 100%;
    height: 100%;
    max-width: $defaultWidth;

    @media (max-width: $maxWidth) {
      max-width: 100%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog {
      width: 80%;
    }
  }
}
