@import "../../styles/sizes";

.pickerWrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .picker {
    height: 30%;
    width: 100%;
    max-width: $defaultWidth;
    @media (max-width: $maxWidth) {
      max-width: 100%;
    }
  }
}

.contentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.imageContainer {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px auto 0 auto;

  .image {
    height: 64px;
    width: 64px;
    border-radius: 40px;
  }
  .image > img {
    height: 64px;
    width: 64px;
    border-radius: 40px;
  }
}
.editImageButton {
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #9500AD;
  margin: 10px auto 36px auto;
  position: relative;
}
.editImageButton > input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
}
.buttonWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;
}
.cropperWrap {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000000;
  z-index: 4;

  .cropButtons {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .cropButton {
      color: #FFFFFF;
      font-weight: 400;
      font-size: 19px;
      margin: 0 25px 0 25px;
      cursor: pointer;
    }
  }
}
.cropperWrapHidden {
  display: none;
}


.datePickerWrapper {
  position: relative;
  z-index: 1;
  height: 79px;
  max-width: $defaultWidth;
  @media (max-width: $maxWidth) {
    max-width: 100%;
  }
  width: 100%;
  opacity: 0;
}
.datePickerWrapper > div > div {
  height: 79px;
}
.calendarIcon {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 8px;
  right: 10px;
}
.extraText {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 14px;
  right: 10px;
  text-align: center;
}
